import { ThemeOptions } from '@mui/material/styles'

export const themeOptions: ThemeOptions = {
	typography: {
		fontFamily: `"DM Sans", "Helvetica", "Arial", sans-serif`,
		h1: {
			fontFamily: `"Futura Passata", "Helvetica", "Arial", sans-serif`,
			fontSize: '64px',
			fontWeight: 700,
			textTransform: 'uppercase',
			whiteSpace: 'pre-line'
		},
		h2: {
			fontFamily: `"Futura Passata", "Helvetica", "Arial", sans-serif`,
			fontSize: '24',
			fontWeight: 700,
			textTransform: 'uppercase',
			whiteSpace: 'pre-line'
		},
		h3: {
			fontFamily: `"Futura Passata", "Helvetica", "Arial", sans-serif`,
			fontSize: '18',
			fontWeight: 700,
			textTransform: 'uppercase',
			whiteSpace: 'pre-line'
		},
		h4: {
			fontFamily: `"Futura Passata", "Helvetica", "Arial", sans-serif`,
			fontSize: '16',
			fontWeight: 700,
			textTransform: 'uppercase',
			whiteSpace: 'pre-line'
		},
		h5: {
			fontFamily: `"Futura Passata", "Helvetica", "Arial", sans-serif`,
			fontSize: '12',
			fontWeight: 700,
			textTransform: 'uppercase',
			whiteSpace: 'pre-line'
		},
		caption: {
			fontFamily: `"Futura Passata", "Helvetica", "Arial", sans-serif`,
			fontSize: '10px',
			fontWeight: 700,
			textTransform: 'uppercase',
			whiteSpace: 'pre-line'
		},
		body1: {
			fontSize: '14px'
		},
		subtitle2: {
			fontFamily: `"Helvetica", "Arial", sans-serif`,
			fontSize: '14px',
			fontWeight: 600
		},
		button: {
			fontWeight: 700,
			fontFamily: `"Futura Passata", "Helvetica", "Arial", sans-serif`,
			textTransform: 'none'
		}
	},
	palette: {
		mode: 'light',
		background: {
			default: '#FFFFFF'
		},
		primary: {
			main: '#000000DE'
		},
		secondary: {
			main: '#0073FF'
		},
		success: {
			main: '#1DBF6E'
		}
	},
	components: {
		MuiButton: {
			defaultProps: {
				disableElevation: true
			}
		},
		MuiOutlinedInput: {
			styleOverrides: {
				root: {
					backgroundColor: '#FAF8F5',
					fontSize: '16px'
				},
				input: {
					backgroundColor: '#FAF8F5',
					fontSize: '16px'
				}
			}
		},
		MuiInputBase: {
			styleOverrides: {
				input: {
					backgroundColor: '#FAF8F5',
					fontSize: '16px'
				}
			}
		},
		MuiFilledInput: {
			styleOverrides: {
				root: {
					backgroundColor: '#FAF8F5'
				}
			}
		},
		MuiFormHelperText: {
			styleOverrides: {
				root: {
					fontFamily: `"DM Sans", "Helvetica", "Arial", sans-serif`,
					marginLeft: '0px',
					textTransform: 'none',
					fontSize: '12px'
				}
			}
		}
	}
}
