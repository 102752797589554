import { useMemo } from 'react'
import i18n from 'i18next'
import * as Yup from 'yup'

import { getPartner } from '../../service/partners.service'
import { prepareCustomMessages } from '../../yup'

prepareCustomMessages()

export const useSchema = () => {
	return useMemo(() => {
		return Yup.object().shape({
			name: Yup.string()
				.max(255)
				.when('code', {
					is: (code: string) => !code,
					then: (schema) => schema.required(i18n.t('general.validation.required'))
				}),
			phoneNumber: Yup.string()
				.max(255)
				.when('code', {
					is: (code: string) => !code,
					then: (schema) =>
						schema
							.required(i18n.t('general.validation.required'))
							.test('empty-or-matches', i18n.t('general.validation.phoneNumber'), function (value) {
								return (
									value === undefined ||
									value === null ||
									value === '' ||
									/^(\+\d{1,4}\s?)?(\d{3}\s?\d{3}\s?\d{3})$/.test(value)
								)
							})
				}),
			email: Yup.string()
				.max(255)
				.when('code', {
					is: (code: string) => !code,
					then: (schema) =>
						schema.required(i18n.t('general.validation.required')).email(i18n.t('general.validation.email'))
				}),
			code: Yup.string()
				.max(255)
				.test('is-required', i18n.t('general.validation.required'), function (value) {
					const { name, email, phoneNumber } = this.parent
					return !!value || !!name || !!email || !!phoneNumber
				})
				.test('is-valid-code', i18n.t('general.validation.invalidCode'), async function (value) {
					if (!value) return true
					try {
						await getPartner(value)
						return true
					} catch (error) {
						return false
					}
				})
		})
	}, [])
}
