import { OrdersApi } from '../generated/api/breadlove'
import { PersistOrderState } from '../store/slices/persistOrderSlice'
import { SessionOrderState } from '../store/slices/sessionOrderSlice'
import axios from './axios'

const ordersApi = new OrdersApi(undefined, process.env.REACT_APP_BE_URL, axios)

const PICKUP_POINT_ID = 1
export const createOrder = (
	sessionOrder: SessionOrderState,
	persistOrder: PersistOrderState,
	partnerId: number | undefined
) => {
	const products = sessionOrder.products!.filter((value) => value.count > 0)
	return ordersApi.createOrder({
		type: partnerId ? 'b2b' : 'b2c',
		email: !partnerId ? persistOrder.email : undefined,
		name: !partnerId ? persistOrder.name : undefined,
		open: true,
		phoneNumber: !partnerId ? persistOrder.phoneNumber : undefined,
		pickupDate: new Date(sessionOrder.pickupDate!).toISOString(),
		pickupTime: sessionOrder.pickupTime,
		pickupPointId: PICKUP_POINT_ID,
		emailNotification: true,
		products: products,
		partnerId: partnerId
	})
}

export const getOpeningHours = (date: Date) => {
	return ordersApi.getOpeningHours(date.toISOString())
}
