/* tslint:disable */
/* eslint-disable */
/**
 * Breadlove API
 * API for Breadlove tool
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from './configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
import type { RequestArgs } from './base';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface OpeningHoursDto
 */
export interface OpeningHoursDto {
    /**
     * 
     * @type {boolean}
     * @memberof OpeningHoursDto
     */
    'open': boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof OpeningHoursDto
     */
    'pickupTimes': Array<OpeningHoursDtoPickupTimesEnum>;
}

export const OpeningHoursDtoPickupTimesEnum = {
    _1: 'SLOT_1',
    _2: 'SLOT_2',
    _3: 'SLOT_3'
} as const;

export type OpeningHoursDtoPickupTimesEnum = typeof OpeningHoursDtoPickupTimesEnum[keyof typeof OpeningHoursDtoPickupTimesEnum];

/**
 * 
 * @export
 * @interface OrderDto
 */
export interface OrderDto {
    /**
     * 
     * @type {string}
     * @memberof OrderDto
     */
    'pickupTime'?: OrderDtoPickupTimeEnum;
    /**
     * 
     * @type {number}
     * @memberof OrderDto
     */
    'id': number;
    /**
     * 
     * @type {Array<OrderItemDto>}
     * @memberof OrderDto
     */
    'products': Array<OrderItemDto>;
    /**
     * 
     * @type {PartnerDto}
     * @memberof OrderDto
     */
    'partner'?: PartnerDto;
    /**
     * 
     * @type {number}
     * @memberof OrderDto
     */
    'pickupPointId': number;
    /**
     * 
     * @type {string}
     * @memberof OrderDto
     */
    'pickupDate': string;
    /**
     * 
     * @type {string}
     * @memberof OrderDto
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof OrderDto
     */
    'email': string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderDto
     */
    'phoneNumber': string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderDto
     */
    'note': string | null;
    /**
     * 
     * @type {boolean}
     * @memberof OrderDto
     */
    'open': boolean;
    /**
     * 
     * @type {string}
     * @memberof OrderDto
     */
    'type': OrderDtoTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof OrderDto
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof OrderDto
     */
    'updatedAt': string;
}

export const OrderDtoPickupTimeEnum = {
    _1: 'SLOT_1',
    _2: 'SLOT_2',
    _3: 'SLOT_3'
} as const;

export type OrderDtoPickupTimeEnum = typeof OrderDtoPickupTimeEnum[keyof typeof OrderDtoPickupTimeEnum];
export const OrderDtoTypeEnum = {
    B2B: 'b2b',
    B2C: 'b2c'
} as const;

export type OrderDtoTypeEnum = typeof OrderDtoTypeEnum[keyof typeof OrderDtoTypeEnum];

/**
 * 
 * @export
 * @interface OrderItemDto
 */
export interface OrderItemDto {
    /**
     * 
     * @type {number}
     * @memberof OrderItemDto
     */
    'productId': number;
    /**
     * 
     * @type {number}
     * @memberof OrderItemDto
     */
    'count': number;
}
/**
 * 
 * @export
 * @interface OrderItemReqDto
 */
export interface OrderItemReqDto {
    /**
     * 
     * @type {number}
     * @memberof OrderItemReqDto
     */
    'productId': number;
    /**
     * 
     * @type {number}
     * @memberof OrderItemReqDto
     */
    'count': number;
}
/**
 * 
 * @export
 * @interface OrderPatchReqDto
 */
export interface OrderPatchReqDto {
    /**
     * 
     * @type {boolean}
     * @memberof OrderPatchReqDto
     */
    'open': boolean;
}
/**
 * 
 * @export
 * @interface OrderReqDto
 */
export interface OrderReqDto {
    /**
     * 
     * @type {string}
     * @memberof OrderReqDto
     */
    'pickupTime'?: OrderReqDtoPickupTimeEnum;
    /**
     * 
     * @type {string}
     * @memberof OrderReqDto
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof OrderReqDto
     */
    'type': OrderReqDtoTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof OrderReqDto
     */
    'email'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderReqDto
     */
    'phoneNumber'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderReqDto
     */
    'note'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof OrderReqDto
     */
    'pickupPointId': number;
    /**
     * 
     * @type {string}
     * @memberof OrderReqDto
     */
    'pickupDate': string;
    /**
     * 
     * @type {Array<OrderItemReqDto>}
     * @memberof OrderReqDto
     */
    'products': Array<OrderItemReqDto>;
    /**
     * 
     * @type {boolean}
     * @memberof OrderReqDto
     */
    'emailNotification'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof OrderReqDto
     */
    'partnerId'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof OrderReqDto
     */
    'open': boolean;
}

export const OrderReqDtoPickupTimeEnum = {
    _1: 'SLOT_1',
    _2: 'SLOT_2',
    _3: 'SLOT_3'
} as const;

export type OrderReqDtoPickupTimeEnum = typeof OrderReqDtoPickupTimeEnum[keyof typeof OrderReqDtoPickupTimeEnum];
export const OrderReqDtoTypeEnum = {
    B2B: 'b2b',
    B2C: 'b2c'
} as const;

export type OrderReqDtoTypeEnum = typeof OrderReqDtoTypeEnum[keyof typeof OrderReqDtoTypeEnum];

/**
 * 
 * @export
 * @interface PartnerDto
 */
export interface PartnerDto {
    /**
     * 
     * @type {number}
     * @memberof PartnerDto
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof PartnerDto
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof PartnerDto
     */
    'emailA': string;
    /**
     * 
     * @type {string}
     * @memberof PartnerDto
     */
    'emailB': string;
}
/**
 * 
 * @export
 * @interface PartnersSummaryOrderDto
 */
export interface PartnersSummaryOrderDto {
    /**
     * 
     * @type {string}
     * @memberof PartnersSummaryOrderDto
     */
    'orderName': string;
    /**
     * 
     * @type {number}
     * @memberof PartnersSummaryOrderDto
     */
    'productId': number;
    /**
     * 
     * @type {number}
     * @memberof PartnersSummaryOrderDto
     */
    'count': number;
}
/**
 * 
 * @export
 * @interface PickupPointDto
 */
export interface PickupPointDto {
    /**
     * 
     * @type {number}
     * @memberof PickupPointDto
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof PickupPointDto
     */
    'name': string;
}
/**
 * 
 * @export
 * @interface ProductDto
 */
export interface ProductDto {
    /**
     * 
     * @type {number}
     * @memberof ProductDto
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof ProductDto
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof ProductDto
     */
    'code': string;
    /**
     * 
     * @type {number}
     * @memberof ProductDto
     */
    'limit': number;
    /**
     * 
     * @type {number}
     * @memberof ProductDto
     */
    'opened': number;
    /**
     * 
     * @type {number}
     * @memberof ProductDto
     */
    'closed': number;
    /**
     * 
     * @type {number}
     * @memberof ProductDto
     */
    'count': number;
    /**
     * 
     * @type {number}
     * @memberof ProductDto
     */
    'price': number;
    /**
     * 
     * @type {boolean}
     * @memberof ProductDto
     */
    'shop': boolean;
    /**
     * 
     * @type {number}
     * @memberof ProductDto
     */
    'deadlineLimit': number;
    /**
     * 
     * @type {number}
     * @memberof ProductDto
     */
    'order': number;
}
/**
 * 
 * @export
 * @interface RemainingProductDto
 */
export interface RemainingProductDto {
    /**
     * 
     * @type {number}
     * @memberof RemainingProductDto
     */
    'id': number;
    /**
     * 
     * @type {number}
     * @memberof RemainingProductDto
     */
    'remaining': number;
    /**
     * 
     * @type {number}
     * @memberof RemainingProductDto
     */
    'deadlineLimit': number;
}
/**
 * 
 * @export
 * @interface TypeformAnswerDto
 */
export interface TypeformAnswerDto {
    /**
     * 
     * @type {object}
     * @memberof TypeformAnswerDto
     */
    'type': object;
    /**
     * 
     * @type {TypeformAnswerDtoField}
     * @memberof TypeformAnswerDto
     */
    'field': TypeformAnswerDtoField;
    /**
     * 
     * @type {string}
     * @memberof TypeformAnswerDto
     */
    'text'?: string;
    /**
     * 
     * @type {string}
     * @memberof TypeformAnswerDto
     */
    'date'?: string;
    /**
     * 
     * @type {string}
     * @memberof TypeformAnswerDto
     */
    'phone_number'?: string;
    /**
     * 
     * @type {string}
     * @memberof TypeformAnswerDto
     */
    'email'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof TypeformAnswerDto
     */
    'boolean'?: boolean;
    /**
     * 
     * @type {TypeformAnswerDtoChoice}
     * @memberof TypeformAnswerDto
     */
    'choice': TypeformAnswerDtoChoice;
}
/**
 * 
 * @export
 * @interface TypeformAnswerDtoChoice
 */
export interface TypeformAnswerDtoChoice {
    /**
     * 
     * @type {string}
     * @memberof TypeformAnswerDtoChoice
     */
    'other'?: string;
    /**
     * 
     * @type {string}
     * @memberof TypeformAnswerDtoChoice
     */
    'label'?: string;
}
/**
 * 
 * @export
 * @interface TypeformAnswerDtoField
 */
export interface TypeformAnswerDtoField {
    /**
     * 
     * @type {string}
     * @memberof TypeformAnswerDtoField
     */
    'id'?: string;
}
/**
 * 
 * @export
 * @interface TypeformReqDto
 */
export interface TypeformReqDto {
    /**
     * 
     * @type {TypeformResponseDto}
     * @memberof TypeformReqDto
     */
    'form_response': TypeformResponseDto;
    /**
     * 
     * @type {string}
     * @memberof TypeformReqDto
     */
    'event_id': string;
    /**
     * 
     * @type {string}
     * @memberof TypeformReqDto
     */
    'event_type': string;
}
/**
 * 
 * @export
 * @interface TypeformResponseDto
 */
export interface TypeformResponseDto {
    /**
     * 
     * @type {string}
     * @memberof TypeformResponseDto
     */
    'form_id': string;
    /**
     * 
     * @type {Array<TypeformAnswerDto>}
     * @memberof TypeformResponseDto
     */
    'answers': Array<TypeformAnswerDto>;
}
/**
 * 
 * @export
 * @interface WeeklySummaryOrderDto
 */
export interface WeeklySummaryOrderDto {
    /**
     * 
     * @type {number}
     * @memberof WeeklySummaryOrderDto
     */
    'dayOfWeek': number;
    /**
     * 
     * @type {number}
     * @memberof WeeklySummaryOrderDto
     */
    'productId': number;
    /**
     * 
     * @type {number}
     * @memberof WeeklySummaryOrderDto
     */
    'count': number;
}

/**
 * HealthApi - axios parameter creator
 * @export
 */
export const HealthApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        health: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/health`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * HealthApi - functional programming interface
 * @export
 */
export const HealthApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = HealthApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async health(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.health(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * HealthApi - factory interface
 * @export
 */
export const HealthApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = HealthApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        health(options?: any): AxiosPromise<string> {
            return localVarFp.health(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * HealthApi - object-oriented interface
 * @export
 * @class HealthApi
 * @extends {BaseAPI}
 */
export class HealthApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HealthApi
     */
    public health(options?: AxiosRequestConfig) {
        return HealthApiFp(this.configuration).health(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * OrdersApi - axios parameter creator
 * @export
 */
export const OrdersApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {OrderReqDto} orderReqDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createOrder: async (orderReqDto: OrderReqDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'orderReqDto' is not null or undefined
            assertParamExists('createOrder', 'orderReqDto', orderReqDto)
            const localVarPath = `/orders`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(orderReqDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteOrder: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteOrder', 'id', id)
            const localVarPath = `/orders/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} date 
         * @param {'SLOT_1' | 'SLOT_2' | 'SLOT_3'} [pickupTime] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllOrders: async (date: string, pickupTime?: 'SLOT_1' | 'SLOT_2' | 'SLOT_3', options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'date' is not null or undefined
            assertParamExists('getAllOrders', 'date', date)
            const localVarPath = `/orders`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (date !== undefined) {
                localVarQueryParameter['date'] = (date as any instanceof Date) ?
                    (date as any).toISOString() :
                    date;
            }

            if (pickupTime !== undefined) {
                localVarQueryParameter['pickupTime'] = pickupTime;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} date 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOpeningHours: async (date: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'date' is not null or undefined
            assertParamExists('getOpeningHours', 'date', date)
            const localVarPath = `/orders/opening-hours`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (date !== undefined) {
                localVarQueryParameter['date'] = (date as any instanceof Date) ?
                    (date as any).toISOString() :
                    date;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrder: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getOrder', 'id', id)
            const localVarPath = `/orders/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} date 
         * @param {'SLOT_1' | 'SLOT_2' | 'SLOT_3'} [pickupTime] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPartnersSummaryOrders: async (date: string, pickupTime?: 'SLOT_1' | 'SLOT_2' | 'SLOT_3', options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'date' is not null or undefined
            assertParamExists('getPartnersSummaryOrders', 'date', date)
            const localVarPath = `/orders/partners-summary`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (date !== undefined) {
                localVarQueryParameter['date'] = (date as any instanceof Date) ?
                    (date as any).toISOString() :
                    date;
            }

            if (pickupTime !== undefined) {
                localVarQueryParameter['pickupTime'] = pickupTime;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} date 
         * @param {'SLOT_1' | 'SLOT_2' | 'SLOT_3'} [pickupTime] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWeeklySummaryOrders: async (date: string, pickupTime?: 'SLOT_1' | 'SLOT_2' | 'SLOT_3', options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'date' is not null or undefined
            assertParamExists('getWeeklySummaryOrders', 'date', date)
            const localVarPath = `/orders/weekly-summary`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (date !== undefined) {
                localVarQueryParameter['date'] = (date as any instanceof Date) ?
                    (date as any).toISOString() :
                    date;
            }

            if (pickupTime !== undefined) {
                localVarQueryParameter['pickupTime'] = pickupTime;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {OrderPatchReqDto} orderPatchReqDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchOrder: async (id: number, orderPatchReqDto: OrderPatchReqDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('patchOrder', 'id', id)
            // verify required parameter 'orderPatchReqDto' is not null or undefined
            assertParamExists('patchOrder', 'orderPatchReqDto', orderPatchReqDto)
            const localVarPath = `/orders/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(orderPatchReqDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {OrderReqDto} orderReqDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOrder: async (id: number, orderReqDto: OrderReqDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateOrder', 'id', id)
            // verify required parameter 'orderReqDto' is not null or undefined
            assertParamExists('updateOrder', 'orderReqDto', orderReqDto)
            const localVarPath = `/orders/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(orderReqDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * OrdersApi - functional programming interface
 * @export
 */
export const OrdersApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = OrdersApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {OrderReqDto} orderReqDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createOrder(orderReqDto: OrderReqDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrderDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createOrder(orderReqDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteOrder(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrderDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteOrder(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} date 
         * @param {'SLOT_1' | 'SLOT_2' | 'SLOT_3'} [pickupTime] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllOrders(date: string, pickupTime?: 'SLOT_1' | 'SLOT_2' | 'SLOT_3', options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<OrderDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllOrders(date, pickupTime, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} date 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOpeningHours(date: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OpeningHoursDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getOpeningHours(date, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOrder(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrderDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getOrder(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} date 
         * @param {'SLOT_1' | 'SLOT_2' | 'SLOT_3'} [pickupTime] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPartnersSummaryOrders(date: string, pickupTime?: 'SLOT_1' | 'SLOT_2' | 'SLOT_3', options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PartnersSummaryOrderDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPartnersSummaryOrders(date, pickupTime, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} date 
         * @param {'SLOT_1' | 'SLOT_2' | 'SLOT_3'} [pickupTime] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getWeeklySummaryOrders(date: string, pickupTime?: 'SLOT_1' | 'SLOT_2' | 'SLOT_3', options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<WeeklySummaryOrderDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getWeeklySummaryOrders(date, pickupTime, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {OrderPatchReqDto} orderPatchReqDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async patchOrder(id: number, orderPatchReqDto: OrderPatchReqDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrderDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.patchOrder(id, orderPatchReqDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {OrderReqDto} orderReqDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateOrder(id: number, orderReqDto: OrderReqDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrderDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateOrder(id, orderReqDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * OrdersApi - factory interface
 * @export
 */
export const OrdersApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = OrdersApiFp(configuration)
    return {
        /**
         * 
         * @param {OrderReqDto} orderReqDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createOrder(orderReqDto: OrderReqDto, options?: any): AxiosPromise<OrderDto> {
            return localVarFp.createOrder(orderReqDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteOrder(id: number, options?: any): AxiosPromise<OrderDto> {
            return localVarFp.deleteOrder(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} date 
         * @param {'SLOT_1' | 'SLOT_2' | 'SLOT_3'} [pickupTime] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllOrders(date: string, pickupTime?: 'SLOT_1' | 'SLOT_2' | 'SLOT_3', options?: any): AxiosPromise<Array<OrderDto>> {
            return localVarFp.getAllOrders(date, pickupTime, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} date 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOpeningHours(date: string, options?: any): AxiosPromise<OpeningHoursDto> {
            return localVarFp.getOpeningHours(date, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrder(id: number, options?: any): AxiosPromise<OrderDto> {
            return localVarFp.getOrder(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} date 
         * @param {'SLOT_1' | 'SLOT_2' | 'SLOT_3'} [pickupTime] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPartnersSummaryOrders(date: string, pickupTime?: 'SLOT_1' | 'SLOT_2' | 'SLOT_3', options?: any): AxiosPromise<Array<PartnersSummaryOrderDto>> {
            return localVarFp.getPartnersSummaryOrders(date, pickupTime, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} date 
         * @param {'SLOT_1' | 'SLOT_2' | 'SLOT_3'} [pickupTime] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWeeklySummaryOrders(date: string, pickupTime?: 'SLOT_1' | 'SLOT_2' | 'SLOT_3', options?: any): AxiosPromise<Array<WeeklySummaryOrderDto>> {
            return localVarFp.getWeeklySummaryOrders(date, pickupTime, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {OrderPatchReqDto} orderPatchReqDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchOrder(id: number, orderPatchReqDto: OrderPatchReqDto, options?: any): AxiosPromise<OrderDto> {
            return localVarFp.patchOrder(id, orderPatchReqDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {OrderReqDto} orderReqDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOrder(id: number, orderReqDto: OrderReqDto, options?: any): AxiosPromise<OrderDto> {
            return localVarFp.updateOrder(id, orderReqDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * OrdersApi - object-oriented interface
 * @export
 * @class OrdersApi
 * @extends {BaseAPI}
 */
export class OrdersApi extends BaseAPI {
    /**
     * 
     * @param {OrderReqDto} orderReqDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrdersApi
     */
    public createOrder(orderReqDto: OrderReqDto, options?: AxiosRequestConfig) {
        return OrdersApiFp(this.configuration).createOrder(orderReqDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrdersApi
     */
    public deleteOrder(id: number, options?: AxiosRequestConfig) {
        return OrdersApiFp(this.configuration).deleteOrder(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} date 
     * @param {'SLOT_1' | 'SLOT_2' | 'SLOT_3'} [pickupTime] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrdersApi
     */
    public getAllOrders(date: string, pickupTime?: 'SLOT_1' | 'SLOT_2' | 'SLOT_3', options?: AxiosRequestConfig) {
        return OrdersApiFp(this.configuration).getAllOrders(date, pickupTime, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} date 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrdersApi
     */
    public getOpeningHours(date: string, options?: AxiosRequestConfig) {
        return OrdersApiFp(this.configuration).getOpeningHours(date, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrdersApi
     */
    public getOrder(id: number, options?: AxiosRequestConfig) {
        return OrdersApiFp(this.configuration).getOrder(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} date 
     * @param {'SLOT_1' | 'SLOT_2' | 'SLOT_3'} [pickupTime] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrdersApi
     */
    public getPartnersSummaryOrders(date: string, pickupTime?: 'SLOT_1' | 'SLOT_2' | 'SLOT_3', options?: AxiosRequestConfig) {
        return OrdersApiFp(this.configuration).getPartnersSummaryOrders(date, pickupTime, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} date 
     * @param {'SLOT_1' | 'SLOT_2' | 'SLOT_3'} [pickupTime] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrdersApi
     */
    public getWeeklySummaryOrders(date: string, pickupTime?: 'SLOT_1' | 'SLOT_2' | 'SLOT_3', options?: AxiosRequestConfig) {
        return OrdersApiFp(this.configuration).getWeeklySummaryOrders(date, pickupTime, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {OrderPatchReqDto} orderPatchReqDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrdersApi
     */
    public patchOrder(id: number, orderPatchReqDto: OrderPatchReqDto, options?: AxiosRequestConfig) {
        return OrdersApiFp(this.configuration).patchOrder(id, orderPatchReqDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {OrderReqDto} orderReqDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrdersApi
     */
    public updateOrder(id: number, orderReqDto: OrderReqDto, options?: AxiosRequestConfig) {
        return OrdersApiFp(this.configuration).updateOrder(id, orderReqDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * PartnersApi - axios parameter creator
 * @export
 */
export const PartnersApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllPartners: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/partners`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} code 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPartner: async (code: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'code' is not null or undefined
            assertParamExists('getPartner', 'code', code)
            const localVarPath = `/partners/{code}`
                .replace(`{${"code"}}`, encodeURIComponent(String(code)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PartnersApi - functional programming interface
 * @export
 */
export const PartnersApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PartnersApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllPartners(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PartnerDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllPartners(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} code 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPartner(code: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PartnerDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPartner(code, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PartnersApi - factory interface
 * @export
 */
export const PartnersApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PartnersApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllPartners(options?: any): AxiosPromise<Array<PartnerDto>> {
            return localVarFp.getAllPartners(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} code 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPartner(code: string, options?: any): AxiosPromise<PartnerDto> {
            return localVarFp.getPartner(code, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PartnersApi - object-oriented interface
 * @export
 * @class PartnersApi
 * @extends {BaseAPI}
 */
export class PartnersApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnersApi
     */
    public getAllPartners(options?: AxiosRequestConfig) {
        return PartnersApiFp(this.configuration).getAllPartners(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} code 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnersApi
     */
    public getPartner(code: string, options?: AxiosRequestConfig) {
        return PartnersApiFp(this.configuration).getPartner(code, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * PickupPointsApi - axios parameter creator
 * @export
 */
export const PickupPointsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllPickupPoints: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/pickup-points`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PickupPointsApi - functional programming interface
 * @export
 */
export const PickupPointsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PickupPointsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllPickupPoints(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PickupPointDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllPickupPoints(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PickupPointsApi - factory interface
 * @export
 */
export const PickupPointsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PickupPointsApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllPickupPoints(options?: any): AxiosPromise<Array<PickupPointDto>> {
            return localVarFp.getAllPickupPoints(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PickupPointsApi - object-oriented interface
 * @export
 * @class PickupPointsApi
 * @extends {BaseAPI}
 */
export class PickupPointsApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PickupPointsApi
     */
    public getAllPickupPoints(options?: AxiosRequestConfig) {
        return PickupPointsApiFp(this.configuration).getAllPickupPoints(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ProductsApi - axios parameter creator
 * @export
 */
export const ProductsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} date 
         * @param {boolean} [shop] 
         * @param {'SLOT_1' | 'SLOT_2' | 'SLOT_3'} [pickupTime] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllProducts: async (date: string, shop?: boolean, pickupTime?: 'SLOT_1' | 'SLOT_2' | 'SLOT_3', options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'date' is not null or undefined
            assertParamExists('getAllProducts', 'date', date)
            const localVarPath = `/products`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (date !== undefined) {
                localVarQueryParameter['date'] = (date as any instanceof Date) ?
                    (date as any).toISOString() :
                    date;
            }

            if (shop !== undefined) {
                localVarQueryParameter['shop'] = shop;
            }

            if (pickupTime !== undefined) {
                localVarQueryParameter['pickupTime'] = pickupTime;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} date 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRemainingProductsAfterDeadline: async (date: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'date' is not null or undefined
            assertParamExists('getRemainingProductsAfterDeadline', 'date', date)
            const localVarPath = `/products/remaining-after-deadline`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (date !== undefined) {
                localVarQueryParameter['date'] = (date as any instanceof Date) ?
                    (date as any).toISOString() :
                    date;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ProductsApi - functional programming interface
 * @export
 */
export const ProductsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ProductsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} date 
         * @param {boolean} [shop] 
         * @param {'SLOT_1' | 'SLOT_2' | 'SLOT_3'} [pickupTime] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllProducts(date: string, shop?: boolean, pickupTime?: 'SLOT_1' | 'SLOT_2' | 'SLOT_3', options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ProductDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllProducts(date, shop, pickupTime, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} date 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getRemainingProductsAfterDeadline(date: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<RemainingProductDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getRemainingProductsAfterDeadline(date, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ProductsApi - factory interface
 * @export
 */
export const ProductsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ProductsApiFp(configuration)
    return {
        /**
         * 
         * @param {string} date 
         * @param {boolean} [shop] 
         * @param {'SLOT_1' | 'SLOT_2' | 'SLOT_3'} [pickupTime] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllProducts(date: string, shop?: boolean, pickupTime?: 'SLOT_1' | 'SLOT_2' | 'SLOT_3', options?: any): AxiosPromise<Array<ProductDto>> {
            return localVarFp.getAllProducts(date, shop, pickupTime, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} date 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRemainingProductsAfterDeadline(date: string, options?: any): AxiosPromise<Array<RemainingProductDto>> {
            return localVarFp.getRemainingProductsAfterDeadline(date, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ProductsApi - object-oriented interface
 * @export
 * @class ProductsApi
 * @extends {BaseAPI}
 */
export class ProductsApi extends BaseAPI {
    /**
     * 
     * @param {string} date 
     * @param {boolean} [shop] 
     * @param {'SLOT_1' | 'SLOT_2' | 'SLOT_3'} [pickupTime] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductsApi
     */
    public getAllProducts(date: string, shop?: boolean, pickupTime?: 'SLOT_1' | 'SLOT_2' | 'SLOT_3', options?: AxiosRequestConfig) {
        return ProductsApiFp(this.configuration).getAllProducts(date, shop, pickupTime, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} date 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductsApi
     */
    public getRemainingProductsAfterDeadline(date: string, options?: AxiosRequestConfig) {
        return ProductsApiFp(this.configuration).getRemainingProductsAfterDeadline(date, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * TypeformApi - axios parameter creator
 * @export
 */
export const TypeformApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} typeformSignature 
         * @param {TypeformReqDto} typeformReqDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webhookB2B: async (typeformSignature: string, typeformReqDto: TypeformReqDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'typeformSignature' is not null or undefined
            assertParamExists('webhookB2B', 'typeformSignature', typeformSignature)
            // verify required parameter 'typeformReqDto' is not null or undefined
            assertParamExists('webhookB2B', 'typeformReqDto', typeformReqDto)
            const localVarPath = `/typeform-webhook/b2b`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (typeformSignature != null) {
                localVarHeaderParameter['typeform-signature'] = String(typeformSignature);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(typeformReqDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} typeformSignature 
         * @param {TypeformReqDto} typeformReqDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webhookB2C: async (typeformSignature: string, typeformReqDto: TypeformReqDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'typeformSignature' is not null or undefined
            assertParamExists('webhookB2C', 'typeformSignature', typeformSignature)
            // verify required parameter 'typeformReqDto' is not null or undefined
            assertParamExists('webhookB2C', 'typeformReqDto', typeformReqDto)
            const localVarPath = `/typeform-webhook/b2c`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (typeformSignature != null) {
                localVarHeaderParameter['typeform-signature'] = String(typeformSignature);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(typeformReqDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TypeformApi - functional programming interface
 * @export
 */
export const TypeformApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TypeformApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} typeformSignature 
         * @param {TypeformReqDto} typeformReqDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async webhookB2B(typeformSignature: string, typeformReqDto: TypeformReqDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.webhookB2B(typeformSignature, typeformReqDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} typeformSignature 
         * @param {TypeformReqDto} typeformReqDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async webhookB2C(typeformSignature: string, typeformReqDto: TypeformReqDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.webhookB2C(typeformSignature, typeformReqDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * TypeformApi - factory interface
 * @export
 */
export const TypeformApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TypeformApiFp(configuration)
    return {
        /**
         * 
         * @param {string} typeformSignature 
         * @param {TypeformReqDto} typeformReqDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webhookB2B(typeformSignature: string, typeformReqDto: TypeformReqDto, options?: any): AxiosPromise<void> {
            return localVarFp.webhookB2B(typeformSignature, typeformReqDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} typeformSignature 
         * @param {TypeformReqDto} typeformReqDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webhookB2C(typeformSignature: string, typeformReqDto: TypeformReqDto, options?: any): AxiosPromise<void> {
            return localVarFp.webhookB2C(typeformSignature, typeformReqDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * TypeformApi - object-oriented interface
 * @export
 * @class TypeformApi
 * @extends {BaseAPI}
 */
export class TypeformApi extends BaseAPI {
    /**
     * 
     * @param {string} typeformSignature 
     * @param {TypeformReqDto} typeformReqDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TypeformApi
     */
    public webhookB2B(typeformSignature: string, typeformReqDto: TypeformReqDto, options?: AxiosRequestConfig) {
        return TypeformApiFp(this.configuration).webhookB2B(typeformSignature, typeformReqDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} typeformSignature 
     * @param {TypeformReqDto} typeformReqDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TypeformApi
     */
    public webhookB2C(typeformSignature: string, typeformReqDto: TypeformReqDto, options?: AxiosRequestConfig) {
        return TypeformApiFp(this.configuration).webhookB2C(typeformSignature, typeformReqDto, options).then((request) => request(this.axios, this.basePath));
    }
}


