import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit'

import { RootState } from '../index'

export type PersistOrderState = {
	phoneNumber?: string
	email?: string
	name?: string
	code?: string
}

const initialState: PersistOrderState = {}

const sessionOrderSlice = createSlice({
	name: 'persistOrder',
	initialState: initialState,
	reducers: {
		setStep4: (
			state,
			action: PayloadAction<{ phoneNumber?: string; email?: string; name?: string; code?: string }>
		) => {
			state.phoneNumber = action.payload.phoneNumber
			state.email = action.payload.email
			state.name = action.payload.name
			state.code = action.payload.code
		},
		reset: () => initialState
	}
})

export const persistOrderReducer = sessionOrderSlice.reducer
export const persistOrderActions = sessionOrderSlice.actions

const selectSlice = (state: RootState) => state.persistedReducers.persistOrder

export const persistOrderState = createSelector(selectSlice, (orderState: PersistOrderState) => orderState)
